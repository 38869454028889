/* mat-select overrides */
@use '@angular/material' as mat;

.mat-input-container {
  line-height: 1.2 !important;
}

.mat-select-placeholder {
  width: auto !important;
}

.mat-list .mat-list-item .mat-list-icon.mat-checkbox {
  width: auto;
  height: auto;
  font-size: inherit;
}

.mat-card-actions {
  display: flex !important;
  justify-content: flex-end;
}

.mat-tab-group.full-screen {
  flex: 1;

  .mat-tab-body-wrapper {
    flex: 1;
  }
}

.mat-tab-label, .mat-tab-link {
  min-width: 100px !important;
}

.mat-dialog-content {
  margin-bottom: 16px !important;
}

.mat-dialog-actions {
  padding: 12px !important;
  margin-right: -24px;
  margin-left: -24px;
  display: flex;

  .mat-button, .mat-raised-button {
    margin: 0 4px;
    flex: 1;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.mat-form-field {
  font-size: mat.font-size($app-typography, input) !important;

  &:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix > .mat-button .mat-icon {
    display: inline-block;
  }
}

/* ngx-datatable overrides */

ngx-datatable {
  font-size: 14px;
}

.datatable-checkbox {
  padding: 0 !important;
  line-height: 0;
  vertical-align: baseline !important;

  input[type=checkbox] {
    display: block;
  }
}

.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  display: inline !important;
}

.datatable-body-row.datatable-row-even {
  background-color: #fcfcfc;
}

.ngx-datatable.material {
  .datatable-body .datatable-body-row .datatable-body-cell,
  .datatable-header .datatable-header-cell {
    padding: 0.5rem 1.2rem;
    color: inherit;
  }
}

/* ngx-dnd overrides */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
}

.gu-mirror.mat-list-item .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  position: relative;
}
