/* You can add global styles to this file, and also import other style files */

@import 'styles/variables';

@import '~@swimlane/ngx-datatable/themes/material';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@import url('https://fonts.googleapis.com/css?family=Oswald');

@import 'styles/material';
@import 'styles/icomoon';
@import 'styles/global';
@import 'styles/overrides';
@import 'styles/print';
