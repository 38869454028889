@media print {
  .mat-sidenav-container {
    overflow: visible !important;
  }
  .mat-sidenav, .page-footer {
    display: none !important;
  }
  .mat-sidenav-content {
    margin: 0 !important;
  }
}
