@use '@angular/material' as mat;
router-outlet + * {
  display: flex;
  flex-direction: column;
  height: 100%;

  > .page-bar, > .page-footer {
    flex-shrink: 0;
  }
}

.site-bar {
  z-index: 200;
  @include mat.elevation(3);
}

.page-bar {
  position: relative;
  z-index: 100;
  background-color: mat.get-color-from-palette($app-theme-primary);
  color: mat.get-contrast-color-from-palette($app-theme-primary, 500);
  @include mat.elevation(1);

  .toolbar-icon {
    height: 100%;
    margin: 0 16px 0 -16px;
    padding: 0;
    width: 64px;
    min-width: 64px;
    background-color: mat.get-contrast-color-from-palette($app-theme-primary, 500);
    color: mat.get-color-from-palette($app-theme-primary) !important;
    border-radius: 0;

    .mat-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      width: 64px;
      height: 64px;
      line-height: 1;

      &.chevron {
        position: absolute;
        left: 0;
        width: auto;
        font-size: 24px;
      }
    }
  }
}


.toolbar-title {
  flex: 1 0;

  &:not(:first-child) {
    margin-left: 8px;
  }
}

.scroll-content {
  flex: 1;
  overflow: auto;
}

.padding {
  padding: 16px;
}

.no-padding {
  padding: 0 !important;
}

.color-warn {
  color: mat.get-color-from-palette($app-theme-warn);
}


a {
  text-decoration: none;
  color: mat.get-color-from-palette($app-theme-primary);
  cursor: pointer;

  &:active {
    color: mat.get-color-from-palette($app-theme-accent);
  }

  &:hover {
    color: mat.get-color-from-palette($app-theme-primary, darker);
  }
}

th {
  text-align: left;
}

hr.spacer {
  border-color: transparent;
  margin: 8px 0;
}

.kana {
  font-size: 0.6em;
  line-height: 1;
}

.postal-input {
  width: 220px;

  // .mat-icon {
  //   font-size: 12px;
  // }

  .mat-button {
    font-size: 12px;
    line-height: 30px;
    padding: 0 8px;
  }
}


.meta-card {
  background-color: $black-6-opacity;
}

.meta-table {
  th {
    width: 30%;
  }

  th, td {
    height: 32px;
  }
}

.page-footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $black-12-opacity;
  padding: 12px 16px;
  background-color: white;
}

.page-footer-left > * {
  margin-right: 8px !important;
}

.page-footer-right > * {
  margin-left: 8px !important;
}

.error-messages {
  padding: 16px;
  background-color: map-get($app-theme-warn, default);
  color: map-get($app-theme-warn, default-contrast);
  border-radius: 2px;
}


.search-area {
  position: absolute;
  z-index: 98;
  top: 62px;
  width: 100%;

  .mat-card {
    width: calc(100% - 32px);
    max-width: 768px;
    margin: 0 auto;
  }

  .search-control-row {
    margin: 0 -8px;
  }

  .search-control {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.input-group {
  &.checkbox {
    margin: 1.5em 0;
  }

  > label {
    display: inline-block;
    width: 120px;
  }

  &.wide {
    > label {
      width: 180px;
    }
  }
}

.input-xs {
  width: 80px;
}

.gutter-right {
  margin-right: 12px;
}

.full-width {
  width: 100%;

  &.mat-select .mat-select-trigger {
    min-width: auto;
  }
}

.mat-form-field {
  &.full-height {
    height: 100%;

    .mat-form-field-wrapper, .mat-form-field-flex, .mat-form-field-infix, .mat-input-element {
      height: 100%;
    }
  }

  &.densed {
    .mat-form-field-flex {
      padding-top: 0;
    }
  }

  .mat-form-field-infix {
    width: auto;
  }
}

.auto-width {
  width: auto;
}

.in-form {
  .mat-select-trigger {
    margin: 0 0 1.296875em;
    height: 31px;
  }

  &.plain-text {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
  }

  &.mat-button-toggle-group .mat-button-toggle-label-content {
    line-height: 30px;
  }
}

.in-table {
  &.mat-select {
    padding-top: 0;
  }

  &.mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-flex {
      padding-top: 0;
    }

    .mat-form-field-infix {
      border-top-width: 0;
    }

    .mat-form-field-underline {
      bottom: 0;
    }

    .mat-form-field-subscript-wrapper {
      top: auto;
    }
  }
}

.button-toggle-with-placeholder {
  position: relative;
  margin: 1em 0;

  .placeholder {
    color: rgba(black, 0.38);
    position: absolute;
    padding: 0 2px;
    top: -22px;
    left: -2px;
    transform: scale(0.75);
    transform-origin: left top;
  }
}

.sortable-list {
  display: flex;

  .sortable-list-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    a {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.mat-list-item {
  &.list-placeholder {
    color: rgba(black, 0.38);
  }
  &.selected {
    background-color: mat.get-color-from-palette(mat.$blue-palette, 100, 0.2);
  }
}

.mat-card > .mat-toolbar:first-child {
  margin: -16px -16px 16px;
  width: auto;
  min-height: 48px;
  font-size: 16px;
  background-color: mat.get-color-from-palette($app-theme-primary, lighter, 0.3);
  color: mat.get-color-from-palette($app-theme-primary, lighter-contrast);
  // border-bottom: 1px solid mat.get-color-from-palette($app-theme-primary, default);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  .mat-toolbar-row {
    height: 48px;
  }
}

.mat-card-content.filled {
  margin-right: -16px;
  margin-left: -16px;

  &:first-child, .mat-toolbar + & {
    margin-top: -16px;
  }

  &:last-child:not(.mat-card-footer) {
    margin-bottom: -16px;
  }

  &:not(.nobody) .mat-tab-body-content {
    padding: 16px 24px;
  }

  .mat-list {
    padding-bottom: 8px;
  }
}

.progress-spinner-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.capsule-badge {
  display: inline-flex;
  font-size: 10px;
  line-height: 1;
  background-color: $black-6-opacity;
  color: $black-87-opacity;
  border-radius: 9px;
  margin-right: 8px;
}

.capsule-badge-label {
  padding: 4px 4px 4px 6px;
  white-space: nowrap;
}

.capsule-badge-value {
  padding: 4px 6px 4px 4px;
  border-radius: 0 9px 9px 0;

  .deterioration-1 & {
    background-color: mat.get-color-from-palette(mat.$yellow-palette, 500);
    color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 500);
  }
  .deterioration-2 & {
    background-color: mat.get-color-from-palette(mat.$amber-palette, 500);
    color: mat.get-contrast-color-from-palette(mat.$amber-palette, 500);
  }
  .deterioration-3 & {
    background-color: mat.get-color-from-palette(mat.$orange-palette, 500);
    color: mat.get-contrast-color-from-palette(mat.$orange-palette, 500);
  }
  .deterioration-4 & {
    background-color: mat.get-color-from-palette(mat.$red-palette, 500);
    color: mat.get-contrast-color-from-palette(mat.$red-palette, 500);
  }
  .deterioration-5 & {
    background-color: mat.get-color-from-palette(mat.$red-palette, A700);
    color: mat.get-contrast-color-from-palette(mat.$red-palette, A700);
  }
  .urgency-1 & {
    background-color: mat.get-color-from-palette(mat.$yellow-palette, 500);
    color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 500);
  }
  .urgency-2 & {
    background-color: mat.get-color-from-palette(mat.$orange-palette, 500);
    color: mat.get-contrast-color-from-palette(mat.$orange-palette, 500);
  }
  .urgency-3 & {
    background-color: mat.get-color-from-palette(mat.$red-palette, A700);
    color: mat.get-contrast-color-from-palette(mat.$red-palette, A700);
  }
}

.rating-number {
  display: inline-flex;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: $black-87-opacity;
  color: white;
  border-radius: 12px;
  justify-content: center;
  margin-right: 8px;

  &.number-1, &.rating-1 {
    background-color: mat.get-color-from-palette(mat.$red-palette, 500);
  }

  &.number-2 {
    background-color: mat.get-color-from-palette(mat.$orange-palette, 600);
  }

  &.number-3, &.rating-2 {
    background-color: mat.get-color-from-palette(mat.$yellow-palette, 700);
  }

  &.number-4 {
    background-color: mat.get-color-from-palette(mat.$lime-palette, 600);
  }

  &.number-5, &.rating-3 {
    background-color: mat.get-color-from-palette(mat.$green-palette, 500);
  }

  &.rating-none {
    background-color: $black-6-opacity;
  }
}

