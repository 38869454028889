@use '@angular/material' as mat;

$app-theme-primary: mat.define-palette(mat.$blue-palette);
$app-theme-accent:  mat.define-palette(mat.$orange-palette, A200, A100, A400);
$app-theme-warn:    mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme($app-theme-primary, $app-theme-accent, $app-theme-warn);

$app-typography: mat.define-typography-config(
  $font-family: "Roboto, 'Helvetica Neue', system-ui, sans-serif",
  $input: mat.define-typography-level(16px, 1.125, 400)
);

$icomoon-font-path: "fonts";

$black-6-opacity: rgba(black, 0.06);
$black-12-opacity: rgba(black, 0.12);
$black-87-opacity: rgba(black, 0.87);
