@font-face {
  font-family: 'icomoon';
  src:
    url('#{$icomoon-font-path}/icomoon.ttf?yw4h33') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?yw4h33') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?yw4h33#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 24px;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
